<template>
  <div>
    <div
      v-loading.fullscreen.lock="isLoading"
      element-loading-background="rgba(0, 0, 0, 0.5)"
      :element-loading-custom-class="'loading-custom'"
    >
      <router-view />
    </div>
  </div>
</template>

<style>
/* 新增旋轉圖示的樣式 */
.loading-custom .el-loading-spinner {
  position: absolute;
  top: 50%;
  width: 100%;
  transform: translateY(-50%);
  z-index: 2200;
}

.loading-custom .el-loading-spinner .circular {
  margin: 0 auto;
  width: 50px;
  height: 50px;
}

.loading-custom .el-loading-spinner .path {
  stroke: #fff;
  stroke-width: 3px;
  stroke-opacity: 0.9;
}
</style>

<script>
import axios from 'axios'

export default {
  name: 'App',
  data () {
    return {
      isLoading: false,
      cancelTokenSource: null
    }
  },
  methods: {
    cancelRequest () {
      if (this.cancelTokenSource) {
        this.cancelTokenSource.cancel('使用者取消請求')
        this.isLoading = false
      }
    }
  },
  created () {
    this.$http.interceptors.request.use(
      config => {
        this.isLoading = true
        // 建立新的 CancelToken
        this.cancelTokenSource = axios.CancelToken.source()
        config.cancelToken = this.cancelTokenSource.token
        return config
      },
      error => {
        this.isLoading = false
        return Promise.reject(error)
      }
    )

    this.$http.interceptors.response.use(
      response => {
        this.isLoading = false
        this.cancelTokenSource = null
        return response
      },
      error => {
        this.isLoading = false
        this.cancelTokenSource = null
        // 如果是使用者取消的請求，不要顯示錯誤訊息
        if (axios.isCancel(error)) {
          console.log('Request canceled:', error.message)
          return Promise.reject(error)
        }
        return Promise.reject(error)
      }
    )
  }
}
</script>
